import React from 'react';
import moment from 'moment';
import { updateBooking } from 'data-graphql';
import {
  Text,
  Button,
  Form,
  Spacer,
  FlexBox,
  Card,
  Icon,
  SendEmailForm
} from 'components';
import {
  yes_no_options,
  booking_state_options,
  edits_status_options,
  rate_status_options,
  color_options,
  estimateDates,
  estimateRate,
  isMTI
} from 'helpers';
import { toast } from 'react-toastify';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';

export default class EditsForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      saving: false
    };
  }

  componentDidMount() {
    this.setInitialValues();
  }

  close = () => {
    this.setState(this.initialState);
    //this.props.onClose();
  };

  submit = async () => {
    const today = moment().format();
    if (this.state.state.value === 'expired') {
      const isExpired = moment(this.state.expiration).isBefore(today, 'day');
      if (!isExpired) {
        this.setState({ stateError: true });
        toast.error(
          'State cannot be expired if closing date is in the future.'
        );
        return false;
      }
    } else if (
      this.state.state.value === 'active' ||
      this.state.state.value === 'booked'
    ) {
      const isNotExpired = moment(this.state.expiration).isSameOrAfter(
        today,
        'day'
      );
      if (!isNotExpired) {
        this.setState({ stateError: true });
        toast.error(
          'Booking cannot be unexpired if closing date is in the past.'
        );
        return false;
      }
    }

    let payload = {
      ...(this.state.is_production_access !== null && {
        is_production_access: this.state.is_production_access.value
      }),
      ...(this.state.state && { state: this.state.state.value }),
      ...(this.state.opening && { opening: this.state.opening }),
      ...(this.state.expiration && { expiration: this.state.expiration }),

      production_active_date: this.state.production_active_date,
      ...(this.state.rehearsal_active_date && {
        rehearsal_active_date: this.state.rehearsal_active_date
      }),
      ...(this.state.edits_status && {
        edits_status: this.state.edits_status.value
      }),
      ...(this.state.notes_color && {
        notes_color: this.state.notes_color.value
      }),
      ...(this.state.authorized_device_max && {
        authorized_device_max: parseFloat(this.state.authorized_device_max)
      }),
      ...(this.state.rehearsal_editing_device_max && {
        rehearsal_editing_device_max: parseFloat(
          this.state.rehearsal_editing_device_max
        )
      }),
      ...(this.state.rehearsal_edit_code !== null && {
        rehearsal_edit_code: this.state.rehearsal_edit_code
      }),
      ...(this.state.disable_payment_requirement !== null && {
        disable_payment_requirement: this.state.disable_payment_requirement
      }),
      notes: this.state.notes
    };

    if (!isMTI) {
      payload = {
        ...payload,
        ...(this.state.rate && { rate: parseFloat(this.state.rate) }),
        ...(this.state.is_license_ready !== null && {
          is_license_ready: this.state.is_license_ready
        }),
        ...(this.state.is_payment_ready !== null && {
          is_payment_ready: this.state.is_payment_ready
        }),
        ...(this.state.educational !== null && {
          educational: this.state.educational.value
        }),
        ...(this.state.quote_avg_attend && {
          quote_avg_attend: parseFloat(this.state.quote_avg_attend)
        }),
        ...(this.state.quote_avg_price && {
          quote_avg_price: parseFloat(this.state.quote_avg_price)
        }),
        ...(this.state.is_contract_ready !== null && {
          is_contract_ready: this.state.is_contract_ready
        }),
        ...(this.state.quote_performances && {
          quote_performances: parseFloat(this.state.quote_performances)
        }),
        ...(this.state.rate_status && {
          rate_status: this.state.rate_status.value
        })
      };
    }

    const VARS = {
      id: this.props.row.original.id,
      input: payload
    };

    const REQ_OBJECT = `{
      code,
      success,
      message,
      booking {
        id
        show {
          name
        }
        opening
        expiration
        rehearsal_active_date
        production_active_date
        ${
          isMTI
            ? ''
            : `rate
          is_production_access
          is_contract_ready
          is_license_ready
          is_payment_ready
          educational`
        }
        edits_status
        notes
        rate
        state
        is_downloaded
      }
    }`;

    this.setState(prevState => ({
      saving: !prevState.saving
    }));

    const RESULT = await updateBooking(VARS, REQ_OBJECT);

    if (RESULT.data && RESULT.data.success) {
      toast.success(`${RESULT.data.updateBooking.message}`);
      this.props.onChange();
    } else {
      this.setState(prevState => ({
        saving: !prevState.saving
      }));
    }
  };

  cancel = async () => {
    const confirm = window.confirm(
      'Are you Sure you want to Cancel this Booking?'
    );
    if (confirm) {
      const VARS = {
        id: this.props.row.original.id,
        input: { state: 'cancelled' }
      };
      const REQ_OBJECT = `{
        code
        success
        message
      }`;

      const RESULT = await updateBooking(VARS, REQ_OBJECT);

      if (RESULT.data && RESULT.data.success) {
        toast.success(`${RESULT.data.updateBooking.message}`);
        this.props.onChange();
      }
    }
  };

  handleBookingChange = bookingState => {
    this.setState({ state: bookingState, stateError: false });
  };

  handleProdTracksChange = prodSelect => {
    this.setState(
      {
        is_production_access: prodSelect
      },
      this.estimateNewRate
    );
  };

  handleEditsStatusChange = editSelect => {
    this.setState({
      edits_status: editSelect
    });
  };

  handleRateStatusChange = rateSelect => {
    this.setState({
      rate_status: rateSelect
    });
  };

  handleNotesColorChange = noteColor => {
    this.setState({
      notes_color: noteColor
    });
  };

  handleRateChange = e => {
    const value = e.target.value;
    this.setState({ rate: value });
  };

  handleChange = e => {
    const value =
      e.target.type === 'radio' || e.target.type === 'checkbox'
        ? e.target.checked
        : e.target.value;

    this.setState({ [e.target.name]: value }, this.estimateNewRate);
  };

  handleDateChange = (selectedDay, modifiers, dayPickerInput) => {
    let returnObj = {};

    returnObj[dayPickerInput.props.name] = selectedDay
      ? moment(selectedDay).format('YYYY-MM-DD')
      : null;

    if (dayPickerInput.props.name === 'opening') {
      returnObj = estimateDates(returnObj, selectedDay);
    }

    const closingSetting =
      dayPickerInput.props.name === 'opening'
        ? moment(returnObj.opening)
        : null;
    closingSetting &&
      this.setState({ expirationDate: new Date(closingSetting) });

    this.setState(returnObj);
    this.setState({ stateError: false });
  };

  estimateNewRate = () => {
    if (isMTI) return false;
    const rate = estimateRate(
      this.state.is_production_access.value,
      this.state.quote_avg_price,
      this.state.quote_avg_attend,
      this.state.quote_performances,
      this.state.educational.value,
      this.props.row.original.created_at
    );
    this.setState({ rate: rate });
  };

  setInitialValues = () => {
    const { original } = this.props.row;
    console.log("Initial booking data:", original);
    // eslint-disable-next-line
    for (const key in original) {
      if (original[key] === false) {
        original[key] = false;
      } else if (!original[key]) {
        original[key] = '';
      }
    }
    // Ensure disable_payment_requirement is properly set to a boolean value
    if (original.disable_payment_requirement === undefined) {
      original.disable_payment_requirement = false;
    }
    console.log("Setting state with disable_payment_requirement:", original.disable_payment_requirement);
    this.setState(this.props.row.original, this.setSelectValues);
  };

  setSelectValues = () => {
    const bookingState = booking_state_options.filter(
      item => item.value === this.state.state
    )[0];
    const prodTracks = yes_no_options.filter(
      item => item.value === this.state.is_production_access
    )[0];
    const editsStatus = edits_status_options.filter(
      item => item.value === this.state.edits_status
    )[0];
    const rateStatus = rate_status_options.filter(
      item => item.value === this.state.rate_status
    )[0];
    const notesColor = color_options.filter(
      item => item.value === this.state.notes_color
    )[0];
    const educational = yes_no_options.filter(
      item => item.value === this.state.educational
    )[0];

    this.setState({
      state: bookingState,
      is_production_access: prodTracks,
      edits_status: editsStatus,
      rate_status: rateStatus,
      notes_color: notesColor,
      educational: educational
    });
  };

  handleEducationalChange = change => {
    this.setState({ educational: change }, this.estimateNewRate);
  };

  handleCheckboxChange = e => {
    console.log(`Checkbox ${e.target.name} changed to ${e.target.checked}`);
    this.setState({ [e.target.name]: e.target.checked });
  };

  render() {
    let { authorizations } = this.props.row.original;

    const created = moment(this.props.row.original.created_at);
    const is2020Price = moment(created).isSameOrAfter('2020-1-1');

    let devices = authorizations.map((auth, i) => {
      return (
        <Card key={i} device highlighted>
          <Icon
            name={
              auth.device.type === 'iPhone' || auth.device.type === 'iPod touch'
                ? 'smartphone-iphone'
                : auth.device.type === 'iPad'
                ? 'tablet-ipad'
                : 'computer'
            }
            style={{ fontSize: 70, paddingBottom: 5 }}
          />
          <Spacer size="12px" />
          <Text large bold>
            {auth.device.type}
          </Text>
          <br />
          <Text soft>{auth.device.description}</Text>
          <Spacer size="8px" />
          <Text large red>
            {auth.is_edit_rehearsal ? 'Pro Code' : 'Production Access'}
          </Text>
          <hr />
          <Spacer size="8px" />
          <div key={i}>
            <Text bold>
              {auth.user.first_name} {auth.user.last_name}
            </Text>
            <br />
            <Text soft>{auth.user.username}</Text>
          </div>
          <Spacer size="8px" />
        </Card>
      );
    });

    if (!this.state.id) return null;

    return (
      <div
        style={{
          margin: '-12px 10px 15px 10px',
          textAlign: 'left',
          backgroundColor: 'white',
          padding: '20px',
          boxShadow:
            'rgba(0, 0, 0, 0.21) 0px 5px 6px -3px, rgba(0, 0, 0, 0.21) 5px 1px 7px -5px, rgba(0, 0, 0, 0.21) -5px 0px 7px -5px'
        }}
      >
        <FlexBox justify="flex-end">
          <Button
            left
            onClick={this.submit}
            style={{
              margin: '0px 20px',
              zIndex: '10'
            }}
          >
            {this.state.saving ? 'Saving...' : 'Save'}
          </Button>
          <Button
            left
            negative
            onClick={this.cancel}
            style={{
              margin: '0px 20px',
              zIndex: '10'
            }}
          >
            Cancel{' '}
            {!this.state.booked_at.length > 0 && !isMTI ? 'Quote' : 'Booking'}
          </Button>
          <Button
            left
            black
            onClick={this.props.onChange}
            style={{
              margin: '0px 20px',
              zIndex: '10'
            }}
          >
            Close
          </Button>
        </FlexBox>
        <Tabs style={{ position: 'relative', top: '-20px', zIndex: '0' }}>
          <TabList>
            <Tab>
              <Text red style={{ display: 'inline-block' }}>
                Edit {this.state.show.name.substring(0, 15) + '...'}
              </Text>
            </Tab>
            <Tab>
              <Text red style={{ display: 'inline-block' }}>
                Send Email
              </Text>
            </Tab>
            <Tab>
              <Text red style={{ display: 'inline-block' }}>
                Authorizations
              </Text>
            </Tab>
            {!isMTI && (
              <Tab>
                <Text red style={{ display: 'inline-block' }}>
                  Quoted Rate
                </Text>
              </Tab>
            )}
          </TabList>

          <TabPanel>
            <Spacer size="30px" />
            <Form.InputWrapper third>
              <Form.Label small> Opening Date </Form.Label>
              <Spacer size="5px" />
              <Form.StyleDate>
                <DayPickerInput
                  value={this.state.opening}
                  onDayChange={this.handleDateChange}
                  name="opening"
                  placeholder={this.state.opening}
                />
              </Form.StyleDate>
            </Form.InputWrapper>
            <Form.InputWrapper third>
              <Form.Label small> Closing/Expiration Date </Form.Label>
              <Spacer size="5px" />
              <Form.StyleDate>
                <DayPickerInput
                  value={this.state.expiration}
                  dayPickerProps={{
                    month: this.state.expirationDate,
                    disabledDays: {
                      before: this.state.expirationDate
                    }
                  }}
                  onDayChange={this.handleDateChange}
                  name="expiration"
                  placeholder="Closing/Expiration"
                />
              </Form.StyleDate>
            </Form.InputWrapper>

            <Form.InputWrapper third>
              {!isMTI && (
                <>
                  <Form.Label small>Rate Status</Form.Label>
                  <Spacer size="5px" />
                  <Form.StyledSelect
                    rateStatus={
                      this.state.rate_status
                        ? this.state.rate_status.value
                        : 'default'
                    }
                    styles={Form.CustomStyles}
                    value={this.state.rate_status}
                    onChange={this.handleRateStatusChange}
                    options={rate_status_options}
                    isSearchable={false}
                  />
                </>
              )}
            </Form.InputWrapper>

            <Form.InputWrapper third>
              <Form.Label small>Rehearsal Track Availability Date </Form.Label>
              <Spacer size="5px" />
              <Form.StyleDate>
                <DayPickerInput
                  value={this.state.rehearsal_active_date}
                  onDayChange={this.handleDateChange}
                  name="rehearsal_active_date"
                  placeholder="Rehearsal Active Date"
                />
              </Form.StyleDate>
            </Form.InputWrapper>

            <Form.InputWrapper third>
              <Form.Label small>
                {' '}
                Production Track Availability Date{' '}
              </Form.Label>
              <Spacer size="5px" />
              <Form.StyleDate>
                <DayPickerInput
                  value={this.state.production_active_date}
                  onDayChange={this.handleDateChange}
                  name="production_active_date"
                  placeholder="Production Active Date"
                />
              </Form.StyleDate>
            </Form.InputWrapper>

            <Form.InputWrapper third>
              {!isMTI && (
                <>
                  <Form.Label small>Edit Status</Form.Label>
                  <Spacer size="5px" />
                  <Form.StyledSelect
                    styles={Form.CustomStyles}
                    value={this.state.edits_status}
                    onChange={this.handleEditsStatusChange}
                    options={edits_status_options}
                    isSearchable={false}
                  />
                </>
              )}
            </Form.InputWrapper>
            {/* {!isMTI && ( */}
            <Form.InputWrapper third>
              <Form.Label small>Booking State</Form.Label>
              <Spacer size="5px" />
              <Form.StyledSelect
                error={this.state.stateError}
                value={this.state.state}
                onChange={this.handleBookingChange}
                options={booking_state_options}
                isSearchable={false}
                styles={Form.CustomStyles}
              />
            </Form.InputWrapper>
            {/* )} */}
            <Form.InputWrapper third>
              <Form.Label small>Include Production Tracks?</Form.Label>
              <Spacer size="5px" />
              <Form.StyledSelect
                styles={Form.CustomStyles}
                value={this.state.is_production_access}
                onChange={this.handleProdTracksChange}
                options={yes_no_options}
                isSearchable={false}
              />
            </Form.InputWrapper>
            <Form.InputWrapper third>
              {!isMTI && (
                <>
                  <Form.Label small>Rate</Form.Label>
                  <Spacer size="5px" />
                  <Form.Input
                    type="number"
                    name="rate"
                    value={this.state.rate}
                    onChange={this.handleRateChange}
                    placeholder="Rate"
                  />
                </>
              )}
            </Form.InputWrapper>
            <Spacer size="5px" />

            {/* DATE FIELDS */}

            <Form.InputWrapper full>
              <Form.Label small>Notes</Form.Label>
              <Spacer size="5px" />
              <Form.TextArea
                placeholder="Notes"
                name="notes"
                value={this.state.notes}
                onChange={this.handleChange}
              />
            </Form.InputWrapper>
            <Spacer size="5px" />
            <Form.InputWrapper third>
              <Form.Label small> Notes Color </Form.Label>
              <Form.StyledSelect
                styles={Form.CustomStyles}
                value={this.state.notes_color}
                onChange={this.handleNotesColorChange}
                options={color_options}
                isSearchable={false}
              />
            </Form.InputWrapper>
            <Form.InputWrapper third>
              <label>
                <Text>Disable Payment Requirement: </Text>
                <Form.CheckBox
                  name="disable_payment_requirement"
                  checked={this.state.disable_payment_requirement}
                  onChange={this.handleCheckboxChange}
                />
              </label>
            </Form.InputWrapper>
            <Spacer size="20px" />
            <br />
          </TabPanel>
          <TabPanel>
            <SendEmailForm
              organization={this.props.organization}
              booking={this.props.row.original}
            />
          </TabPanel>
          <TabPanel>
            <FlexBox justify="flex-end">
              {/* <Form.InputWrapper style={{ margin: '15px !important' }}> */}
              <div style={{ margin: '15px !important' }}>
                <Form.Label>Pro app Code</Form.Label>
                <Form.Input
                  name="rehearsal_edit_code"
                  type="text"
                  value={this.state.rehearsal_edit_code}
                  onChange={this.handleChange}
                />
              </div>
              <div style={{ margin: '15px !important' }}>
                <Form.Label>Allowed Pro Code Device Authorizations</Form.Label>
                <Form.Input
                  name="rehearsal_editing_device_max"
                  type="number"
                  value={this.state.rehearsal_editing_device_max}
                  onChange={this.handleChange}
                />
              </div>
              <div style={{ margin: '15px !important' }}>
                <Form.Label>Allowed Device Authorizations</Form.Label>
                <Form.Input
                  name="authorized_device_max"
                  type="number"
                  value={this.state.authorized_device_max}
                  onChange={this.handleChange}
                />
              </div>
              {/* </Form.InputWrapper> */}
            </FlexBox>
            <Spacer size="20px" />
            {authorizations.length > 0 && <FlexBox>{devices}</FlexBox>}
            {authorizations.length === 0 && (
              <FlexBox direction="column">
                <Spacer size="20px" />
                <Text large>
                  This Booking does not have any authorized devices.
                </Text>
                <Spacer size="20px" />
              </FlexBox>
            )}
          </TabPanel>
          {!isMTI && (
            <TabPanel>
              <Spacer size="30px" />

              <FlexBox style={{ flexWrap: 'wrap' }}>
                <Form.InputWrapper>
                  <div>
                    <Text>Calculating Rate:</Text>
                    <ul
                      style={{
                        listStyle: 'none',
                        margin: '0px 20px',
                        lineHeight: '1.2'
                      }}
                    >
                      <li>
                        If there is no production access then the rate is{' '}
                        {is2020Price ? '$275' : '$300'}.
                      </li>
                      <li>
                        If there is production track access rate starts at{' '}
                        {is2020Price ? '$550' : '$600'}{' '}
                      </li>
                      <li>
                        The max price for Educational is{' '}
                        {is2020Price ? '$1300' : '$1350'}
                      </li>
                      <li>The max price for non-Educational is $3100</li>

                      <li style={{ margin: '10px 0px 0px -5px' }}>
                        Rate is calculated by:
                      </li>
                      <li>
                        Ticket Price * Attendance * Number of Performances * 10%
                      </li>
                    </ul>
                  </div>
                </Form.InputWrapper>
                <Form.InputWrapper style={{ textAlign: 'right' }}>
                  <label>
                    <Text>Contract: </Text>
                    <Form.CheckBox
                      name="is_contract_ready"
                      checked={this.state.is_contract_ready}
                      onChange={this.handleCheckboxChange}
                    />
                  </label>

                  <label>
                    <Text>Payment: </Text>
                    <Form.CheckBox
                      name="is_payment_ready"
                      checked={this.state.is_payment_ready}
                      onChange={this.handleCheckboxChange}
                    />
                  </label>

                  <label>
                    <Text>License: </Text>
                    <Form.CheckBox
                      name="is_license_ready"
                      checked={this.state.is_license_ready}
                      onChange={this.handleCheckboxChange}
                    />
                  </label>
                  <Spacer size="5px" />
                  <Form.InputWrapper third>
                    <Form.Label small dark>
                      Include Production Tracks?
                    </Form.Label>
                    <Spacer size="5px" />
                    <Form.StyledSelect
                      styles={Form.CustomStyles}
                      value={this.state.is_production_access}
                      onChange={this.handleProdTracksChange}
                      options={yes_no_options}
                      isSearchable={false}
                    />
                  </Form.InputWrapper>
                  <Form.InputWrapper third style={{ marginRight: '10px' }}>
                    <Form.Label small dark>
                      Educational?
                    </Form.Label>
                    <Spacer size="5px" />
                    <Form.StyledSelect
                      styles={Form.CustomStyles}
                      value={this.state.educational}
                      onChange={this.handleEducationalChange}
                      options={yes_no_options}
                      isSearchable={false}
                    />
                  </Form.InputWrapper>
                  <Spacer size="5px" />
                  <Form.InputWrapper third style={{ marginRight: '12px' }}>
                    <Form.Label small dark>
                      Rate
                    </Form.Label>
                    <Spacer size="5px" />
                    <Form.Input
                      type="number"
                      value={this.state.rate}
                      onChange={this.handleRateChange}
                    />
                  </Form.InputWrapper>
                </Form.InputWrapper>

                <Form.InputWrapper third>
                  <Form.Label small dark>
                    Average Ticket Price
                  </Form.Label>
                  <Spacer size="5px" />
                  <Form.Input
                    type="number"
                    name="quote_avg_price"
                    value={this.state.quote_avg_price}
                    onChange={this.handleChange}
                  />
                </Form.InputWrapper>

                <Form.InputWrapper third>
                  <Form.Label small dark>
                    Average Audience Attendance
                  </Form.Label>
                  <Spacer size="5px" />
                  <Form.Input
                    type="number"
                    name="quote_avg_attend"
                    value={this.state.quote_avg_attend}
                    onChange={this.handleChange}
                  />
                </Form.InputWrapper>

                <Form.InputWrapper third>
                  <Form.Label small dark>
                    Number of Performances
                  </Form.Label>
                  <Spacer size="5px" />
                  <Form.Input
                    type="number"
                    name="quote_performances"
                    value={this.state.quote_performances}
                    onChange={this.handleChange}
                  />
                </Form.InputWrapper>
              </FlexBox>
            </TabPanel>
          )}
        </Tabs>
      </div>
    );
  }
}
