import React from 'react';
import { Text, Button, Form, Spacer, FlexBox, Icon } from 'components';
import { updateOrganization, isUsernameAvailable } from 'data-graphql';
import { toast } from 'react-toastify';
import moment from 'moment';
import {
  org_status_options,
  org_group_options,
  address_type_options,
  isMTI
} from 'helpers';
import { ClipLoader } from 'react-spinners';
import styled from 'styled-components';

const UserNameIcons = styled.div`
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  margin-left: -45px;
  z-index: 1;
`;
export default class EditOrgForm extends React.Component {
  constructor() {
    super();
    this.state = {
      saving: false,
      loading: false
    };
  }

  componentDidMount() {
    this.setInitialValues();
  }

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value }, this.checkUsername);
  };

  close = () => {
    this.setState(this.initialState);
    this.props.onChange();
  };

  checkUsername = async () => {
    const VARS = {
      username: this.state.username
    };
    const REQ_OBJECT = `{
      boolean
    }`;

    this.setState({ loading: true });

    const RESULT = await isUsernameAvailable(VARS, REQ_OBJECT);
    this.setState({
      username_available: RESULT.data.isUsernameAvailable,
      loading: false
    });
  };

  submit = async () => {
    const payload = {
      ...(this.state.name && { name: this.state.name }),
      ...(this.state.notes && { notes: this.state.notes }),
      ...(this.state.website && { website: this.state.website }),
      ...(this.state.address && { address: this.state.address }),
      ...(this.state.city && { city: this.state.city }),
      ...(this.state.state && { state: this.state.state }),
      ...(this.state.zip && { zip: this.state.zip }),
      ...(this.state.address_type && {
        address_type: this.state.address_type.value
      }),
      ...(this.state.status && { status: this.state.status.value }),
      ...(this.state.group && { group: this.state.group.value }),

      ...(this.state.username && { username: this.state.username }),
      notes: this.state.notes,
      ...(this.state.pricing && !isMTI && { pricing: this.state.pricing })
    };

    const VARS = { id: this.props.organization.id, input: payload };
    const REQ_OBJECT = `{
      code
      success
      message
    }`;

    this.setState(prevState => ({
      saving: !prevState.saving
    }));

    const RESULT = await updateOrganization(VARS, REQ_OBJECT);

    if (RESULT.data) {
      toast.success(`${RESULT.data.updateOrganization.message}`);
      this.close();
    } else {
      this.setState(prevState => ({
        saving: !prevState.saving
      }));
    }
  };

  handleDateChange = (selectedDay, modifiers, dayPickerInput) => {
    let returnObj = {};
    returnObj[dayPickerInput.props.name] = selectedDay
    ? moment(selectedDay).format('YYYY-MM-DD')
    : null;
    this.setState(returnObj);
  };

  handleAddressTypeChange = address => {
    this.setState({ address_type: address });
  };
  handleOrgStatusChange = org_status => {
    this.setState({ status: org_status });
  };
  handleOrgGroupChange = org_group => {
    this.setState({ group: org_group });
  };

  setInitialValues = () => {
    const { organization } = this.props;
    // eslint-disable-next-line
    for (const key in organization) {
      if (organization[key] === false) {
        organization[key] = false;
      } else if (!organization[key]) {
        organization[key] = '';
      }
    }
    this.setState(this.props.organization, this.setSelectValues);

  };

  setSelectValues = () => {
    const addressType = address_type_options.filter(
      item => item.value === this.props.organization.address_type
    )[0];
    const orgStatus = org_status_options.filter(
      item => item.value === this.props.organization.status
    )[0];
    const orgGroup = org_group_options.filter(
      item => item.value === this.props.organization.group
    )[0];

    this.setState({
      address_type: addressType,
      status: orgStatus,
      group: orgGroup
    });
  };

  render() {
    if (!this.state.name) return null;
    return (
      <Form>
        <FlexBox justify="flex-end">
          <Icon onClick={this.close} name="close" />
        </FlexBox>
        <Text red large>
          Edit Organization
        </Text>
        <Spacer size="10px" />
        <Form.InputWrapper>
          <FlexBox>
            <Form.Input
              type="text"
              placeholder="Username"
              value={this.state.username}
              name="username"
              onChange={this.handleChange}
            />
            {this.state.username && (
              <UserNameIcons>
                {this.state.loading ? (
                  <ClipLoader
                    color={'#494949'}
                    size={20}
                    loading={this.state.loading}
                  />
                ) : this.state.username_available &&
                  this.state.username.length > 2 ? (
                  <Icon name="check" green />
                ) : (
                  <Icon name="delete" red />
                )}
              </UserNameIcons>
            )}
          </FlexBox>
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Label small>Organization Name</Form.Label>
          <Spacer size="5px" />
          <Form.Input
            name="name"
            onChange={this.handleChange}
            value={this.state.name}
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Label small>Website</Form.Label>
          <Spacer size="5px" />
          <Form.Input
            name="website"
            onChange={this.handleChange}
            value={this.state.website}
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Label small>Address</Form.Label>
          <Spacer size="5px" />
          <Form.Input
            name="address"
            onChange={this.handleChange}
            value={this.state.address}
          />
        </Form.InputWrapper>

        <Form.InputWrapper>
          <Form.Label small>City</Form.Label>
          <Spacer size="5px" />
          <Form.Input
            name="city"
            onChange={this.handleChange}
            value={this.state.city}
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Label small>State</Form.Label>
          <Spacer size="5px" />
          <Form.Input
            name="state"
            onChange={this.handleChange}
            value={this.state.state}
          />
        </Form.InputWrapper>
        <Form.InputWrapper>
          <Form.Label small>Zipcode</Form.Label>
          <Spacer size="5px" />
          <Form.Input
            name="zip"
            onChange={this.handleChange}
            value={this.state.zip}
          />
        </Form.InputWrapper>

        <Form.InputWrapper>
          <Form.Label small>Address Type</Form.Label>
          <Spacer size="5px" />
          <Form.StyledSelect
            value={this.state.address_type}
            onChange={this.handleAddressTypeChange}
            options={address_type_options}
            isSearchable={false}
            styles={Form.CustomStyles}
          />
        </Form.InputWrapper>

        <Form.InputWrapper>
          <Form.Label small>Organization Status</Form.Label>
          <Spacer size="5px" />
          <Form.StyledSelect
            value={this.state.status}
            onChange={this.handleOrgStatusChange}
            options={org_status_options}
            isSearchable={false}
            styles={Form.CustomStyles}
          />
        </Form.InputWrapper>

        <Form.InputWrapper>
          <Form.Label small>Organization Group</Form.Label>
          <Spacer size="5px" />
          <Form.StyledSelect
            value={this.state.group}
            onChange={this.handleOrgGroupChange}
            options={org_group_options}
            isSearchable={false}
            styles={Form.CustomStyles}
          />
        </Form.InputWrapper>
        {!isMTI && (
          <Form.InputWrapper>
            <Form.Label small>Pricing</Form.Label>
            <Spacer size="5px" />
            <Form.Input
              name="pricing"
              value={this.state.pricing}
              onChange={this.handleChange}
            />
          </Form.InputWrapper>
        )}
        <Spacer size="5px" />
        <Form.InputWrapper full>
          <Form.TextArea
            name="notes"
            onChange={this.handleChange}
            value={this.state.notes}
          />
        </Form.InputWrapper>

        <Spacer size="10px" />

        <Button onClick={this.submit}>
          {this.state.saving ? 'Saving...' : 'Save'}
        </Button>
      </Form>
    );
  }
}
