import React from 'react';
import styled from 'styled-components';
import {
  Wrapper,
  Header,
  Button,
  Menu,
  FormWrapper,
  Form,
  Spacer,
  Text,
  FlexBox,
  Content,
  COLORS,
  Icon
} from 'components';
import {
  getBooking,
  getUserDevices,
  createAuthorization,
  acceptBookingTerms
} from 'data-graphql';
import { SlideDown } from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import { windowSize, isMTI } from 'helpers';
import { Documents, ProductAuth, TrackManager, ProApp } from './components';
import { toast } from 'react-toastify';
import Modal from 'react-modal';
import ModalContent from './components/ModalContent';
import moment from 'moment';
import UserEditing from './components/UserEditing';
Modal.setAppElement('#root');

const StyledDiv = styled.div`
  backgroundcolor: #f1efef;
  z-index: 10;
`;

const StyledSlideDown = styled(SlideDown)`
  transition-duration: 300;
  transition-timing-function: cubic-bezier(0.1, 0.7, 1, 0.1);
`;

const modalStyles = {
  content: {
    background: COLORS.darkBlue,
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    width: '80vw',
    height: '80vh',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
};

export default class ShowDetails extends React.Component {
  constructor() {
    super();
    this.initialState = {
      menu: 'Documents',
      booking: undefined,
      devicesOpen: false,
      username: '',
      device: {},
      devices: undefined,
      user_to_auth: undefined,
      modalOpen: false,
      scrolled: false
    };
    this.state = this.initialState;
  }

  async componentDidMount() {
    await this.getComponentOrganizationBookings();
  }

  getComponentOrganizationBookings = async () => {
    const VARS = { id: this.props.match.params.id };
    const REQ_OBJECT = `{
      id
      show {
        name
        edits_doc_url
        cue_doc_url
      }
      opening
      expiration
      rehearsal_active_date
      production_active_date
      rate
      is_production_access
      edits_status
      rate_status
      notes
      notes_color
      rate
      state
      is_downloaded
      rehearsal_code
      rehearsal_edit_code
      disable_payment_requirement
      ${isMTI ? 'mti_terms_accepted' : ''}
      ${isMTI ? 'foreign_booking_id' : ''}
      authorizations {
        device {
          description
          id
        }
        user {
          id
          username
        }
        is_edit_rehearsal
      }
    }`;

    const RESULT = await getBooking(VARS, REQ_OBJECT);
    this.setState({
      booking: RESULT.data.getBooking
    });
  };

  getUserDevices = async e => {
    await this.handleChange(e);
    const VARS = {
      username: this.state.username
    };
    const REQ_OBJECT = `{
      user {
        id
        username
      }
      devices {
        id
        description
      }
    }`;

    const RESULT = await getUserDevices(VARS, REQ_OBJECT);

    const { getUserDevicesByUsername } = RESULT.data;

    if (RESULT.data.getUserDevicesByUsername) {
      this.setState({
        devices: getUserDevicesByUsername.devices,
        user_to_auth: getUserDevicesByUsername.user.id
      });
    } else {
      this.setState({
        devices: [],
        user_to_auth: undefined
      });
    }
  };

  authorizeDevice = async () => {
    if (!this.state.user_to_auth || !this.state.device) {
      return toast.error('Please select a user and a device to authorize.', {
        autoclose: false
      });
    }

    const VARS = {
      input: {
        user_id: this.state.user_to_auth,
        device_id: this.state.device.id,
        booking_id: this.props.match.params.id
      }
    };

    const REQ_OBJECT = `{
      message
      success
    }`;

    const RESULT = await createAuthorization(VARS, REQ_OBJECT);
    if (RESULT.data && RESULT.data.success) {
      toast.success(RESULT.data.message);
      this.setState(this.initialState, this.closeDevices);
      await this.getComponentOrganizationBookings();
    }
  };

  closeDevices = () => {
    this.setState({ menu: 'Production Access', devicesOpen: false });
  };

  handleDeviceChange = device => {
    this.setState({ device });
  };

  handleChange = e => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    this.setState({ [e.target.name]: value });
  };

  handleUpdates = async () => {
    await this.getComponentOrganizationBookings();
  };

  devicesOpen = () => {
    if (isMTI && this.state.booking.mti_terms_accepted === false) {
      this.setState({ modalOpen: true });
    } else {
      this.setState({
        devicesOpen: this.state.devicesOpen === true ? false : true
      });
    }
  };

  closeModal = () => {
    this.setState({
      modalOpen: this.state.modalOpen === true ? false : true
    });
  };

  acceptTerms = async () => {
    const VARS = {
      id: this.state.booking.id,
      input: {
        mti_terms_accepted: true
      }
    };

    const REQ_OBJECT = `{
      code
      success
      message
      booking {
        id
        show {
          name
          edits_doc_url
          cue_doc_url
        }
        opening
        expiration
        rehearsal_active_date
        production_active_date
        rate
        is_production_access
        edits_status
        rate_status
        notes
        notes_color
        rate
        state
        is_downloaded
        rehearsal_code
        ${isMTI ? 'mti_terms_accepted' : ''}
        ${isMTI ? 'foreign_booking_id' : ''}
        authorizations {
          device {
            description
            id
          }
          user {
            id
            username
          }
        }
      }
    }`;

    const RESULT = await acceptBookingTerms(VARS, REQ_OBJECT);
    if (RESULT.data.success) {
      this.setState({
        booking: RESULT.data.acceptMTIBookingTerms.booking
      });
      this.closeModal();
      this.devicesOpen();
    }
  };

  menuSelect = e => {
    this.setState({
      menu: e.target.value,
      menuOpen: this.state.menuOpen === 'open' ? false : 'open'
    });
  };

  menuOpen = () => {
    this.setState({
      menuOpen: this.state.menuOpen === 'open' ? false : 'open'
    });
  };

  render() {
    const { booking } = this.state;
    const today = moment(new Date());
    const propro = booking
      ? booking.is_production_access && booking.rehearsal_edit_code
      : false;
    const prono = booking
      ? booking.is_production_access && !booking.rehearsal_edit_code
      : false;
    const repro = booking
      ? !booking.is_production_access && booking.rehearsal_edit_code
      : false;
    const reno = booking
      ? !booking.is_production_access && !booking.rehearsal_edit_code
      : false;
    // const phoneWidth =
    //   windowSize === 'phone' ? { padding: '10px !important' } : {};
    if (!booking) return null;
    return (
      <div>
        <Header>
          <Header.Head
            bold
            huge
            style={{ textAlign: 'left', paddingRight: '45px' }}
          >
            {windowSize !== 'phone' ? (
              <FlexBox justify="space-between">
                <div>
                  {booking.show.name}
                  {booking.production_active_date &&
                    booking.is_production_access &&
                    moment(booking.production_active_date).isSameOrBefore(
                      today
                    ) && (
                      <Button onClick={this.devicesOpen} header>
                        Authorize User {this.state.devicesOpen ? '-' : '+'}
                      </Button>
                    )}
                </div>
                <div>
                  <FlexBox direction="column" style={{ textAlign: 'right' }}>
                    <Text large red>
                      Rehearsal Code:
                    </Text>
                    <Spacer size="5px" />
                    <Text>{booking.rehearsal_code}</Text>
                  </FlexBox>
                  {booking.rehearsal_edit_code && (
                    <>
                      <Spacer size="10px" />
                      <FlexBox
                        direction="column"
                        style={{ textAlign: 'right' }}
                      >
                        <Text large red>
                          Pro App Code:
                        </Text>
                        <Spacer size="5px" />
                        <Text>{booking.rehearsal_edit_code}</Text>
                      </FlexBox>
                    </>
                  )}
                </div>
              </FlexBox>
            ) : (
                <FlexBox direction="column" justify="flexStart" align="flexStart">
                  <div>{booking.show.name}</div>
                  <div style={{ textAlign: 'left' }}>
                    <Text>Rehearsal Code:</Text>

                    <Text> {booking.rehearsal_code}</Text>
                  </div>
                  <Spacer size="20px" />
                  {booking.production_active_date &&
                    booking.is_production_access &&
                    moment(booking.production_active_date).isSameOrBefore(
                      today
                    ) && (
                      <Button onClick={this.devicesOpen}>
                        Authorize User {this.state.devicesOpen ? '-' : '+'}
                      </Button>
                    )}
                </FlexBox>
              )}
          </Header.Head>
          {windowSize === 'phone' ? (
            <>
              <Button darkRed menu onClick={this.menuOpen}>
                {this.state.menu} {this.state.menuOpen === 'open' ? '-' : '+'}
              </Button>
              <Content
                pose={this.state.menuOpen === 'open' ? 'open' : 'closed'}
                style={{
                  overflow:
                    this.state.menuOpen === 'open' ? 'visible' : 'hidden'
                }}
              >
                {propro && (
                  <Menu>
                    <button
                      value="Documents"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Documents'}
                    >
                      Documents
                    </button>
                    <button
                      value="Pro app Access"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Pro app Access'}
                    >
                      Pro App Access
                    </button>
                    <button
                      value="Production Access"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Production Access'}
                    >
                      Production Access
                    </button>
                    <button
                      value="Tracks"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Tracks'}
                    >
                      Tracks
                    </button>
                    <button
                      value="Editing"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Editing'}
                    >
                      Editing
                    </button>
                  </Menu>
                )}
                {prono && (
                  <Menu>
                    <button
                      value="Documents"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Documents'}
                    >
                      Documents
                    </button>
                    <button
                      value="Production Access"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Production Access'}
                    >
                      Production Access
                    </button>
                    <button
                      value="Tracks"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Tracks'}
                    >
                      Tracks
                    </button>
                    <button
                      value="Editing"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Editing'}
                    >
                      Editing
                    </button>
                  </Menu>
                )}
                {repro && (
                  <Menu>
                    <button
                      value="Documents"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Documents'}
                    >
                      Documents
                    </button>
                    <button
                      value="Pro app Access"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Pro app Access'}
                    >
                      Pro App Access
                    </button>
                    <button
                      value="Tracks"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Tracks'}
                    >
                      Tracks
                    </button>
                    <button
                      value="Editing"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Editing'}
                    >
                      Editing
                    </button>
                  </Menu>
                )}
                {reno && (
                  <Menu>
                    <button
                      value="Documents"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Documents'}
                    >
                      Documents
                    </button>
                    <button
                      value="Tracks"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Tracks'}
                    >
                      Tracks
                    </button>
                    <button
                      value="Editing"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Editing'}
                    >
                      Editing
                    </button>
                  </Menu>
                )}
              </Content>
            </>
          ) : (
              <>
                {propro && (
                  <Menu>
                    <button
                      value="Documents"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Documents'}
                    >
                      Documents
                  </button>

                    <button
                      value="Pro app Access"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Pro app Access'}
                    >
                      Pro App Access
                  </button>

                    <button
                      value="Production Access"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Production Access'}
                    >
                      Production Access
                  </button>
                    <button
                      value="Tracks"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Tracks'}
                    >
                      Tracks
                  </button>
                    <button
                      value="Editing"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Editing'}
                    >
                      Editing
                    </button>
                  </Menu>
                )}
                {prono && (
                  <Menu>
                    <button
                      value="Documents"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Documents'}
                    >
                      Documents
                  </button>
                    <button
                      value="Production Access"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Production Access'}
                    >
                      Production Access
                  </button>
                    <button
                      value="Tracks"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Tracks'}
                    >
                      Tracks
                  </button>
                    <button
                      value="Editing"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Editing'}
                    >
                      Editing
                    </button>
                  </Menu>
                )}
                {repro && (
                  <Menu>
                    <button
                      value="Documents"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Documents'}
                    >
                      Documents
                  </button>
                    {booking.rehearsal_edit_code && (
                      <button
                        value="Pro app Access"
                        onClick={this.menuSelect}
                        active={this.state.menu === 'Pro app Access'}
                      >
                        Pro App Access
                      </button>
                    )}

                    <button
                      value="Tracks"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Tracks'}
                    >
                      Tracks
                  </button>
                    <button
                      value="Editing"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Editing'}
                    >
                      Editing
                    </button>
                  </Menu>
                )}
                {reno && (
                  <Menu>
                    <button
                      value="Documents"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Documents'}
                    >
                      Documents
                  </button>

                    <button
                      value="Tracks"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Tracks'}
                    >
                      Tracks
                  </button>
                    <button
                      value="Editing"
                      onClick={this.menuSelect}
                      active={this.state.menu === 'Editing'}
                    >
                      Editing
                    </button>
                  </Menu>
                )}
              </>
            )}
        </Header>
        {this.state.booking && (
          <Wrapper small>
            <StyledSlideDown>
              <Modal isOpen={this.state.modalOpen} style={modalStyles}>
                <ModalContent
                  closeModal={this.closeModal}
                  bookingName={booking.show.name}
                  acceptTerms={this.acceptTerms}
                />
              </Modal>
              {this.state.devicesOpen === true && (
                <FormWrapper>
                  <FlexBox
                    justify="flex-end"
                    style={{ marginLeft: '20px', marginTop: '-20px' }}
                  >
                    <Icon
                      name="close"
                      style={{ cursor: 'pointer' }}
                      onClick={this.closeDevices}
                    />
                  </FlexBox>
                  <Form.InputWrapper>
                    <Form.Label>
                      {this.state.user_to_auth
                        ? 'Personal Username: valid!'
                        : 'Personal Username:'}
                    </Form.Label>
                    <Form.Input
                      type="text"
                      placeholder="Personal Username"
                      value={this.state.username}
                      name="username"
                      onChange={this.getUserDevices}
                    // style={usernameStyle}
                    />
                  </Form.InputWrapper>
                  {this.state.devices && this.state.devices.length > 0 && (
                    <Form.InputWrapper>
                      <Form.Label>Select A Device</Form.Label>
                      <Form.StyledSelect
                        value={this.state.device}
                        onChange={this.handleDeviceChange}
                        options={this.state.devices}
                        isSearchable={true}
                        getOptionLabel={option => option.description}
                        getOptionValue={option => option.id}
                        placeholder="Start typing..."
                        styles={Form.CustomStyles}
                      />
                    </Form.InputWrapper>
                  )}
                  {(!this.state.devices || this.state.devices.length < 1) && (
                    <Form.InputWrapper>
                      <Form.Label>
                        This user has no available devices
                      </Form.Label>
                    </Form.InputWrapper>
                  )}
                  <Spacer size="10px" />
                  <Button
                    disabled={
                      !this.state.devices || this.state.devices.length < 1
                    }
                    noHover={
                      !this.state.devices || this.state.devices.length < 1
                    }
                    onClick={
                      !this.state.devices || this.state.devices.length < 1
                        ? null
                        : this.authorizeDevice
                    }
                  >
                    Authorize
                  </Button>
                </FormWrapper>
              )}
            </StyledSlideDown>
            <StyledDiv>
              {this.state.menu === 'Documents' && (
                <Documents show={booking.show} />
              )}
              {this.state.menu === 'Pro app Access' && (
                <ProApp
                  auths={booking.authorizations.filter(
                    auth => auth.is_edit_rehearsal
                  )}
                  booking_id={this.props.match.params.id}
                  rehearsal_edit_code={booking.rehearsal_edit_code}
                  production_access={booking.is_production_access}
                  onChange={this.handleUpdates}
                />
              )}
              {this.state.menu === 'Production Access' && (
                <ProductAuth
                  auths={booking.authorizations.filter(
                    auth => !auth.is_edit_rehearsal
                  )}
                  booking_id={this.props.match.params.id}
                  onChange={this.handleUpdates}
                />
              )}
              {this.state.menu === 'Tracks' && (
                <TrackManager
                  booking={this.state.booking}
                  onChange={this.handleUpdates}
                />
              )}
              {this.state.menu === 'Editing' && (
                <UserEditing
                  booking={this.state.booking}
                  onChange={this.handleUpdates}
                />
              )}
            </StyledDiv>
          </Wrapper>
        )}
      </div>
    );
  }
}
